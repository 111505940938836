import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import TopImage from '@/image/board.png';
import SubTopSection from '@/components/views/elements/common/SubTopSection';
import NoticeList from '@/components/views/board/notice/NoticeList';
import { useNavigate } from 'react-router-dom';
import { noticeStore } from '@/stores/state/NoticeStore';


export default function NoticePage() {
  window.scrollTo(0, 0);
  const navigate = useNavigate();


  useEffect(() => {
    noticeStore.getNoticeList();
  }, [])

  return (
    <>
      <SubTopSection image={TopImage} />
      <NavConatiner>
        <SuvNav className='wrap'>
          <SubNavLink onClick={() => navigate('/SOXHome/board/notice/list')} className={`highlight-sub-nav`}>Notice</SubNavLink>
          <SubNavLink onClick={() => { navigate('/SOXHome/board/qna') }}>QnA</SubNavLink>
        </SuvNav>
      </NavConatiner>
      <Container className='wrap'>
        <NoticeList />
      </Container>
    </>
  )
}
const Container = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
`;


const NavConatiner = styled.section`
  position: sticky;
  top: 60px;
  height: 40px;
  width: 100%;
  background: #fff;
  box-shadow: 0 4px 5px rgba(100,100,100,0.2);
  z-index:1;
`;

const SuvNav = styled.div`
  display: flex;
`;

const SubNavLink = styled.div`
  flex: 1;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  font-size: 19px;
`;