
import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import NoticeContents from '@/components/views/board/notice/NoticeContents'
import NoticeWrite from '@/components/views/board/notice/NoticeWrite'
import QnaContents from '@/components/views/board/qna/QnaContents'
import NoticePage from '@/components/views/pages/NoticePage'
import QnaPage from '@/components/views/pages/QnaPage'

export default function RoutesUI() {
  return (
    <Routes >
      <Route path="/notice/list" element={<NoticePage />}></Route>
      <Route path="/notice/write" element={<NoticeWrite />}></Route>
      <Route path="/notice/content/*" element={<NoticeContents />}></Route>
      <Route path="/qna" element={<QnaPage />}></Route>
      <Route path="/qna/*" element={<QnaContents />}></Route>
    </Routes>
  )
}
