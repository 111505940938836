import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import LinkTo from '../../../../components/units/atoms/Link';
import SOXWLogo from '../../../../image/SoxLogo-bw.png';
import Layer from '../../../../components/units/atoms/Layer';
import CheckAdmin from '../../../units/molecules/CheckAdmin';

export default function Footer() {

  const [layer, setLayer] = useState<boolean>(false);
  const changeMode = () => {
    if (sessionStorage.permission === 'admin-sox') {
      sessionStorage.removeItem('permission');
      window.location.reload();
    } else {
      setLayer(true)
    }

  }
  const close = () => {
    setLayer(false);
  }

  useEffect(() => {
  }, [layer])

  return (
    <>
      <FooterContainer >
        <address className='wrap' >
          {<LinkTo target='/'><Logo src={SOXWLogo} /></LinkTo>}
          <br />
          (82)2-494-4707
          / soxcom@naver.com
          <br />
          301ho, 23, Seokgye-ro 18-gil, Nowon-gu, Seoul, 01886 Republic of Korea
          <br />
          <br />
          © 2018-2023 by S.O.X Co.Ltd. All rights reserved
        </address>
        <Mode onClick={changeMode}>{sessionStorage.permission === 'admin-sox' ? '게스트' : '관리자'}</Mode>
      </FooterContainer>
      {layer === true &&
        <Layer contents={
          <CheckAdmin
            text='아이디 비밀번호 입력'
            topInputTitle='아이디'
            bottomInputTitle='비밀번호'
            handleClose={() => close()}
          />}
          handleClose={() => close()} />}
    </>
  )
}

const FooterContainer = styled.footer`
  position: relative;
  background: #333;
  padding: 40px;
  margin-top: 100px;
  color: #bbb;
  line-height: 28px;
`;

const Logo = styled.img`
  width: 130px;
  height: 100%;
  margin: 10px;
`;


const Mode = styled.span`
  position: absolute;
  right: 5px; bottom: 5px;
  color: #444;
  cursor: pointer;
`;