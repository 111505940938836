import axios, { AxiosResponse } from 'axios';

export interface ResInfo {
  date: number;
}

export interface IResponse<DATA> {
  data: DATA;
}

export const API = {
  get: async <DATA>(
    url: string,
    queryParams: Record<string, unknown> | undefined = undefined,
  ) => {
    if (queryParams) {
      const query_string = new URLSearchParams(
        queryParams as Record<string, string>,
      ).toString();

      if (query_string) url += '?' + query_string;
    }

    const ret: [IResponse<DATA> | undefined, unknown] = [undefined, undefined];

    try {
      const response: AxiosResponse<IResponse<DATA>> = await axios.get<
        IResponse<DATA>
      >(url, {
        // CORS 처리를 위한 false
        withCredentials: false,
      });

      ret[0] = response.data;
    } catch (error) {
      ret[1] = error;
    }

    return ret;
  },
  post: async <DATA, BODY>(url: string, body?: BODY) => {
    const ret: [IResponse<DATA> | undefined, unknown] = [undefined, undefined];

    try {
      const response: AxiosResponse<IResponse<DATA>> = await axios.post<
        IResponse<DATA>
      >(url, body, {
        // CORS 처리를 위한 false
        withCredentials: false,
      });

      ret[0] = response.data;
    } catch (error) {
      ret[1] = error;
    }

    return ret;
  },
  put: async <DATA, BODY>(url: string, body: BODY) => {
    const ret: [IResponse<DATA> | undefined, unknown] = [undefined, undefined];

    try {
      const response: AxiosResponse<IResponse<DATA>> = await axios.put<
        IResponse<DATA>
      >(url, body, {
        // CORS 처리를 위한 false
        withCredentials: false,
      });

      ret[0] = response.data;
    } catch (error) {
      ret[1] = error;
    }

    return ret;
  },
  delete: async <DATA>(
    url: string,
    queryParams: Record<string, string> | undefined = undefined,
  ) => {
    if (queryParams) {
      const query_string = new URLSearchParams(queryParams).toString();
      if (query_string) url += '?' + query_string;
    }

    const ret: [IResponse<DATA> | undefined, unknown] = [undefined, undefined];

    try {
      const response: AxiosResponse<IResponse<DATA>> = await axios.delete<
        IResponse<DATA>
      >(url, {
        // CORS 처리를 위한 false
        withCredentials: false,
      });

      ret[0] = response.data;
    } catch (error) {
      ret[1] = error;
    }

    return ret;
  },
  // uploadPost: async <DATA, BODY>(url: string, body?: BODY) => {
  //   const ret: [IResponse<DATA> | undefined, unknown] = [undefined, undefined];

  //   try {
  //     const response: AxiosResponse<IResponse<DATA>> = await axios.post<
  //       IResponse<DATA>
  //     >(url, body, {
  //       // CORS 처리를 위한 false
  //       withCredentials: false,
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     });

  //     ret[0] = response.data;
  //   } catch (error) {
  //     ret[1] = error;
  //   }

  //   return ret;
  // },
};
