import { type } from 'os';
import React from 'react'
import styled from 'styled-components'

export interface LineProps {
  row?: 'row' | 'column';
  length?: number;
  color?: string | undefined;
  border?: number;
}

export default function EmptyArea({ length, row = 'row', color = '#333', border = 1 }: LineProps) {


  return (
    <Line row={row} length={length} color={color} border={border}>

    </Line>
  )
}

const Line = styled.div<LineProps>`
  ${props => props.row === 'row' ? (
    `content: '';
    position: relative;
    width: ${props.length === undefined ? '100%;' : props.length + 'px;'} 
    margin: 10px 0 10px 0;
    background: ${props.color};
    height: ${props.border}px;
    `
  ) : (
    ` content: '';
      display: inline-block;
      position: relative;
      height: ${props.length === undefined ? '200px;' : props.length + 'px;'} 
      margin: 0 10px 0 10px;
      background: ${props.color};
      width: ${props.border}px;`
  )}
  `;