import { type } from 'os';
import React from 'react'
import styled from 'styled-components'

export interface EmptyProps {
  gap?: number;
  row?: 'row' | 'column';
  type?: 'no-line' | 'line'
  color?: string | undefined;
}

export default function EmptyArea({ gap = 0, row = 'column', type = 'no-line', color = '#333' }: EmptyProps) {


  return (
    <EmptyDiv row={row} gap={gap} type={type} color={color}>

    </EmptyDiv>
  )
}

const EmptyDiv = styled.div<EmptyProps>`
  width: ${props => props.row === 'row' && props.gap + 'px'};
  height: ${props => props.row === 'column' && props.gap + 'px'};
  display: ${props => props.row === 'row' ? 'inline-block' : 'block'};
  ${props => props.type === 'line' && (
    props.row === 'column' ? `
      position: relative;
      top:${props.gap !== undefined && props.gap / 2}px;
      content: '';
      background : ${props.color};
      height: 1px;
      margin-top:${props.gap !== undefined && props.gap / 2}px;
      margin-bottom: ${props.gap !== undefined && props.gap / 2}px;
    ` : `position: relative;
      left:${props.gap !== undefined && props.gap / 2}px;
      content: '';
      background : ${props.color};
      width: 1px;
      height: 100%;
      margin-left:${props.gap !== undefined && props.gap / 2}px;
      margin-right: ${props.gap !== undefined && props.gap / 2}px;
    `
  )}
  `;