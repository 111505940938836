import { height } from '@mui/system';
import React from 'react'
import styled from 'styled-components';

interface layerContent {
  contents: any;
  handleClose: () => void;
}

export default function Layer({ ...props }: layerContent) {
  const colseLayer = (e: any) => {
    e.target === e.currentTarget && props.handleClose();
  }
  return (
    <>
      <BlackBg onClick={(e) => colseLayer(e)}>
        <LayerContainer>
          {props.contents}
        </LayerContainer>
      </BlackBg>
    </>
  )
}
const BlackBg = styled.div`
  position: fixed;
  top:0; left:0;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
`;

const LayerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 10;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
`;