import { action, makeObservable, observable } from 'mobx';
import { userAPI } from '../../api/user';


export interface UserState {
  admin: boolean;
}

export class UserStore implements UserState {
  admin: boolean;

  constructor() {
    this.admin = false;

    makeObservable(this, {
      admin: observable,
      login: action,
      logout: action,
    })
  }

  login = async (user: { id: string, password: string }) => {
    const [res, err] = await userAPI.setAdmin(user);
    if (err) {
      return false;
    } else {
      this.admin = true;
      return res;
    }
  }
  logout = async () => {
    const [res, err] = await userAPI.removeAdmin();
    if (err) {
      return false;
    } else {
      this.admin = false;
      return res;
    }
  }
}

export const userStore = new UserStore();

