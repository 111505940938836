import React from 'react'
import styled from 'styled-components';
import { FlexContainer, FlexItem } from '@/components/units/template/CommonCssTemplate';
import FirstSectionImg from '@/image/first-image.jpg';
import Icon1 from '@/image/icon-1.png';
import Icon2 from '@/image/icon-2.png';
import Icon3 from '@/image/icon-3.png';
import Icon4 from '@/image/icon-4.png';
import Icon5 from '@/image/icon-5.png';
import SoxImage from '@/image/sox-image.jpg';
import StayOnBoxLogo from '@/image/solution-logo01.png';
import DrpImage from '@/image/drp-image.jpg';
import DrpLogo from '@/image/solution-logo02.png';
import LinkTo from '@/components/units/atoms/Link';
import Button from '@/components/units/atoms/Button';
import { ImageCard } from '@/components/units/atoms/Card';
import DevOpsImage from '@/image/devops.jpg';
import BackendImage from '@/image/backend.png';
import FrontendImage from '@/image/frontend.png';
import BoardPreview from '@/components/units/molecules/BoardPreview';
import { noticeStore } from '@/stores/state/NoticeStore';

export default function PortalPage() {


  return (
    <>
      <TopSection>
        <FlexContainer className='wrap'>
          <FlexLongItem>
            <MainImage src={FirstSectionImg} alt="메인이미지" />
          </FlexLongItem>
          <FlexItem>
            <FlexColumn>
              <InnerFlex>
                <FlexItem>
                  <img src={Icon1} alt='icon' />
                </FlexItem>
                <FlexInnerLongItem>
                  Fused Positioning <br />
                  Data Quality Management
                </FlexInnerLongItem>
              </InnerFlex>
              <InnerFlex>
                <FlexItem>
                  <img src={Icon2} alt='icon' />
                </FlexItem>
                <FlexInnerLongItem>
                  LTE/WLAN/GPS<br />
                  Fused Positioning
                </FlexInnerLongItem>
              </InnerFlex>
              <InnerFlex>
                <FlexItem>
                  <img src={Icon3} alt='icon' />
                </FlexItem>
                <FlexInnerLongItem>
                  A WiFi-based Indoor<br />
                  Positioning System
                </FlexInnerLongItem>
              </InnerFlex>
              <InnerFlex>
                <FlexItem>
                  <img src={Icon4} alt='icon' />
                </FlexItem>
                <FlexInnerLongItem>
                  Location based<br />
                  service session control
                </FlexInnerLongItem>
              </InnerFlex>
              <InnerFlex>
                <FlexItem>
                  <img src={Icon5} alt='icon' />
                </FlexItem>
                <FlexInnerLongItem>
                  Spatial information<br />
                  analysis and visualization
                </FlexInnerLongItem>
              </InnerFlex>
            </FlexColumn>
          </FlexItem>
        </FlexContainer>
      </TopSection>
      <BoardPreview boardList={noticeStore.noticeList} />
      <SecondSection className='wrap'>
        <h2 style={{ marginBottom: '80px', textAlign: 'center', fontSize: '28px', fontWeight: 'normal' }}>SOX Solution</h2>
        <FlexContainer>
          <FlexInnerLongItem style={{ height: '350px', boxShadow: '0 0 10px rgba(0,0,0,0.2)' }}>
            <Image src={SoxImage} alt='icon' />
          </FlexInnerLongItem>
          <FlexItem style={{ height: '350px', boxShadow: '0 0 10px rgba(0,0,0,0.15)', padding: '20px' }}>
            <SolutionLogoImage src={StayOnBoxLogo} alt="StayOnBoxLogo" />
            <SoutionText>
              무선신호를 이용하여 사용자의 위치를 찾고 <br />
              위치에 대한 정보를 분석해서<br />
              시각화 해 주는 위치 응용 플랫폼<br />
            </SoutionText>
            <ButtonArea>
              <LinkTo targetOtherSite='http://sox.iptime.org:30080/NeoCopleinService'>
                <Button text={'서비스 보기'} background={"#14457A"} width={200} />
              </LinkTo>
            </ButtonArea>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem style={{ height: '350px', boxShadow: '0 0 10px rgba(0,0,0,0.15)', padding: '20px' }}>
            <SolutionLogoImage src={DrpLogo} alt="DrpLogo" />
            <SoutionText>
              모니터링 현장에 대한 대시보드를<br />
              현장 관리자가 PPT로 제작하여 운용할 수 있는<br />
              loT시각화 솔루션<br />
              <br />
            </SoutionText>
            <ButtonArea>
              <LinkTo targetOtherSite='http://play.drilldown.info/DrilldownPlay/#/main'>
                <Button text={'서비스 보기'} background={"#14457A"} width={200} />
              </LinkTo>
            </ButtonArea>
          </FlexItem>
          <FlexInnerLongItem style={{ height: '350px', boxShadow: '0 0 10px rgba(0,0,0,0.2)' }}>
            <Image src={DrpImage} alt='DrpImage' />
          </FlexInnerLongItem>
        </FlexContainer>
      </SecondSection>
      <ThirdSection>
        <TextSection>
          Why S.O.X ?
        </TextSection>
        <LinkTo targetOtherSite='https://magazine.hankyung.com/job-joy/article/202209163778d'>
          <BottomTextBox title='관련 기사 확인' style={{ "cursor": "pointer" }} className="wrap">
            <h2>‘에스오엑스는 정확합니다’</h2>
            <FlexContainer>
              <FlexItem>-</FlexItem>
              <FlexItem>와이파이 방식</FlexItem>
              <FlexItem>기지국 방식</FlexItem>
            </FlexContainer>
            <FlexContainer>
              <FlexItem><p style={{ color: "rgb(168 0 0)" }}>에스오엑스</p></FlexItem>
              <FlexItem><p style={{ color: "rgb(168 0 0)" }}>20m 이내</p></FlexItem>
              <FlexItem><p style={{ color: "rgb(168 0 0)" }}>30m 이내</p></FlexItem>
            </FlexContainer>
            <FlexContainer>
              <FlexItem>SKT</FlexItem>
              <FlexItem>29.4m</FlexItem>
              <FlexItem>82.0m</FlexItem>
            </FlexContainer>
            <FlexContainer>
              <FlexItem>KT</FlexItem>
              <FlexItem>37.9m</FlexItem>
              <FlexItem>168.4m</FlexItem>
            </FlexContainer>
            <FlexContainer>
              <FlexItem>LG U+</FlexItem>
              <FlexItem>76.9m </FlexItem>
              <FlexItem>155.7m</FlexItem>
            </FlexContainer>
            <br />
            <span style={{ color: '#555', fontSize: '16px' }}>클릭하시면 관련 기사로 이동합니다.</span>
          </BottomTextBox>
        </LinkTo>
        <BottomTextBox>
          <h2>‘‘에스오엑스는 신속합니다’’</h2>
          여러 해를 거쳐 구축해 온 소프트웨어 개발 기술과 인프라를 통해 높은 기능의 작업물을 빠르게 생산할 수 있습니다.
          <br /><br />
          <FlexContainer className='wrap'>
            <FlexItem>
              <ImageCard image={BackendImage} titleText="SOX Service Framework (SSF)" subText={['-JAVA application Framework']} />
            </FlexItem>
            <FlexItem>
              <ImageCard image={FrontendImage} titleText="SOX Frontend Framework (SFF)" subText={['-React library']} />
            </FlexItem>
            <FlexItem>
              <ImageCard image={DevOpsImage} titleText="DevOps System" subText={[]} />
            </FlexItem>
          </FlexContainer>
        </BottomTextBox>
      </ThirdSection>

    </>
  )
}

const TopSection = styled.section`
  background: #041628;
  height: 50vh;
  overflow: hidden;
  padding: 10px 0 10px 0;
`;
const InnerFlex = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  `;

const FlexLongItem = styled.div`
  flex: 3;
  border-radius: 10px;
  height: 47vh;
  overflow: hidden;
`;

const MainImage = styled.img`
  height: 47vh;
  overflow: hidden;
  width: 100%;
`;

const FlexColumn = styled.div`
  background: rgba(255,255,255,0.15);
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 15px;
`;

const FlexInnerLongItem = styled.div`
  flex:2;
  border-radius: 10px;
  overflow: hidden;
  font-size: 15px;
`;

const TextSection = styled.div`
  background: #000;
  color: #fff;
  padding: 30px;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
`;

const BoardPreviewContainer = styled.div`
  position: sticky;
  top: 59px;
  z-index: 10;
  background: #000;
  color: #fff;
  height: 40px;
  text-align: center;
  font-size: 22px;
  box-shadow:  0 3px 5px rgba(100,100,100,0.6);
`;
const SecondSection = styled.section`
  padding: 100px 0 100px 0;
`;

const Image = styled.img`
  width: 100%;
  transform: translateY(-70px);
`;

const SolutionLogoImage = styled.img`
  height: 46px;
`;

const SoutionText = styled.p`
  margin-top: 30px;
  font-size: 18px;
  line-height: 30px;
`;

const ButtonArea = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
`;

const ThirdSection = styled.section`
  margin: 10px 0 100px 0;
  text-align: center;
  line-height: 36px;
`;

const BottomTextBox = styled.div`
  font-size: 20px;

  & > h2 {
    margin: 100px 0 40px 0 ;
  }

  & > div {
    text-align: center;
  }
`;
