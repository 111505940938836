import React, { useMemo, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function EditorComponent({ ...props }: any) {

  const imageHandler = () => {
    props.imgHandler();
  }

  const modules = useMemo(() => {
    return {

      toolbar: {
        container: [
          ['image'],
          [{ header: [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        ],
        // handlers: {
        //   // 이미지 처리는 우리가 직접 imageHandler라는 함수로 처리할 것이다.
        //   image: imageHandler,
        // },
      },
    }
  }, []);


  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image',
    'align', 'color', 'background',
  ]

  const [value, setValue] = useState('');

  const handleChange = (content: any) => {
    setValue(content)
    props.onChage(value);
    console.log(value)
  }

  return (
    <div style={{ height: "440px" }}>
      <ReactQuill
        style={{ height: "440px" }}
        theme="snow"
        modules={modules}
        formats={formats}
        value={value}
        onChange={
          (content) => handleChange(content)} />
    </div>
  )
}
