import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import SOXWLogo from '../../../../image/SoxLogo-bw.png';
import LinkTo from '../../../../components/units/atoms/Link';
import { noticeStore } from '@/stores/state/NoticeStore';

export default function Header() {


  const getPost = async () => {
    await noticeStore.getNoticeList();
  }



  useEffect(() => {
    getPost();
  }, [])
  return (
    <>
      <HeaderContainer>
        <div className='wrap'>
          {<LinkTo target='/SOXHome'><Logo src={SOXWLogo} /></LinkTo>}
          <Menu>
            <List>
              <LinkTo target='/SOXHome/company' color='#fff'>Company</LinkTo>
            </List>
            <List>
              <LinkTo target='/SOXHome/solutions' color='#fff'>Solutions</LinkTo>
            </List>
            <List>
              <LinkTo target='/SOXHome/infra' color='#fff'>Infra</LinkTo>
            </List>
            <List>
              <LinkTo target='/SOXHome/industries' color='#fff'>Industries</LinkTo>
            </List>
            <List>
              <LinkTo target='/SOXHome/media' color='#fff'>Media</LinkTo>
            </List>
            <List>
              <LinkTo target='/SOXHome/board/notice/list' color='#fff'>Board</LinkTo>
            </List>
          </Menu>
        </div>
      </HeaderContainer>
      <HeaderArea />
    </>
  )
}


const HeaderContainer = styled.header`
  position: fixed;
  width: 100%;
  background: #041628;
  height: 60px;
  z-index: 10;
`;

const Logo = styled.img`
  width: 130px;
  height: 100%;
  margin: 10px;
`;

const Menu = styled.ul`
  float: right;

`;

const List = styled.li`
  float: left;
  line-height: 60px;
  margin-left: 45px;
  font-size: 20px;
  color: #fff;
`;

const HeaderArea = styled.div`
  height: 60px;
`;