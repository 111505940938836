import { API } from ".";

export const fileAPI = {
  uploadFile: <DATA, BODY>(body: BODY) => {
    return API.post<DATA, BODY>('/SOXHome/api/file/upload.do', body);
  },

  getFile: <DATA, BODY>(body: BODY) => {
    return API.post<DATA, BODY>('/SOXHome/api/file/download.do', body);
  }
}