import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@/components/units/atoms/Button';
import Line from '@/components/units/atoms/Line';
import { useRef } from 'react';
import { qnaStore } from '@/stores/state/QnaStore';
import { useNavigate } from 'react-router-dom';

export default function Write() {
  const navigate = useNavigate();

  const nameRef = useRef<HTMLInputElement>(null);
  const EmailRef = useRef<HTMLInputElement>(null);
  const contentRef = useRef<HTMLTextAreaElement>(null);

  const [fileArr, setFileArr] = useState<any[]>([]);


  const handleFileUpload = async (e: any) => {
    let formData = new FormData();
    const { files } = e.target;
    formData.append('file', files[0]);
    const result: any = await qnaStore.fileUpload(formData);
    setFileArr([...fileArr, result.resultMap])
  }

  const handleSubmit = async () => {
    const res: any = await qnaStore.addQuestion({
      name: nameRef.current?.value,
      email: EmailRef.current?.value,
      content: contentRef.current?.value.replace(/(?:\r\n|\r|\n)/g, '<br>'),
      file: fileArr
    });

    res.result === 'success' ? alert('문의내용이 접수되었습니다.') : alert('에러가 발생했습니다.');


  }


  return (
    <>
      <Container className='wrap'>
        <h3>문의 남기기</h3>
        <p>문의하실 내용과 답변받으실 메일 주소를 남겨주시면 해당 메일로 답변해드립니다.</p>
        <Line border={2}></Line>
        <Line border={1} color="#ccc"></Line>
        <Row>
          <div style={{ flex: 1, padding: '0  20px' }}>이름</div>
          <div style={{ flex: 2 }}>
            <InputText type="text" ref={nameRef} />
          </div>
          <div style={{ flex: 1, padding: '0 0 0 20px' }}>이메일</div>
          <div style={{ flex: 2 }}>
            <InputText type="text" ref={EmailRef} />
          </div>
          <div style={{ flex: 4 }}></div>
        </Row>
        <Line border={1} color="#ccc"></Line>
        <Row>
          <div style={{ flex: 1, padding: '0 20px' }}>파일</div>
          <div style={{ flex: 9 }}>
            <input type="file" onChange={(e) => handleFileUpload(e)} />
          </div>
        </Row>
        <FileContainer>
          {
            fileArr.map((file: any) => <span style={{ marginRight: '15px' }}>{file.original_name}</span>)
          }
        </FileContainer>
        <Line border={1} color="#ccc"></Line>
        <ContentContainer>
          <TextArea ref={contentRef} ></TextArea>
        </ContentContainer>
        <Line border={2}></Line>
      </Container>
      <ButtonContainer>
        <Button text={"작성"} onClick={handleSubmit}></Button>
        <Button text={"취소"} onClick={() => navigate('/SOXHome/board/notice/list')}></Button>
      </ButtonContainer>
    </>
  )
}


const Container = styled.div`
  padding-top: 60px;
`;

const Row = styled.div`
  display: flex;
  height: 30px;
  line-height: 30px;
`;

const ContentContainer = styled.div`
  padding: 10px;
  height: 500px;
`;

const ButtonContainer = styled.div`
  width: 15vw;
  display: flex;
  gap: 10px;
  margin: 30px auto;
`;

const InputText = styled.input`
  width: 100%;
  border: 1px solid #ccc;
  height: 100%;
  border-radius: 3px;
  padding: 3px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 5px;
  height: 100%;
  resize: none;
  border: 1px solid #ccc;
  border-radius: 3px;
`


const FileContainer = styled.div`
  padding: 10px;
  background: #eee;
  width: 100%;
  height: 50px;
  overflow: auto;
`;