import React from 'react';
import {
  DataGrid,
  DataGridProps,
  GridEnrichedColDef,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import {
  Box,
  Pagination as Page,
  PaginationProps,
  styled,
  Typography,
} from '@mui/material';

export interface IDataGridProps extends DataGridProps {
  disableBorder?: boolean;
  dataState?: 'accident' | 'equiment' | 'accidentLog';
  lightStyle?: boolean;
}

export const StyledDataGrid = styled(({ ...props }: IDataGridProps) => (
  <DataGrid {...props} />
))(
  ({ theme }) => ``,
);

const StyledPagination = styled(({ ...props }: PaginationProps) => (
  <Page {...props} />
))(
  ({ theme }) => `
`,
);

const Pagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <StyledPagination
      page={page + 1}
      count={pageCount}
      onChange={(_, value) => {
        apiRef.current.setPage(value - 1);
      }}
    />
  );
};

export const DataTable = ({
  columns,
  pagination,
  components,
  ...props
}: IDataGridProps) => {
  const copiedColumns = columns.map(
    column =>
    ({
      ...column,
      headerAlign: 'center',
      align: 'center',
    } as GridEnrichedColDef),
  );

  const rowsPerPage = 15;

  return (
    <StyledDataGrid
      columns={copiedColumns}
      getRowId={props.getRowId}
      pageSize={pagination ? rowsPerPage : undefined}
      hideFooterPagination={pagination ? false : true}
      hideFooter={pagination ? false : true}
      disableSelectionOnClick
      components={{
        ...components,
        NoRowsOverlay: NoRowsOverlay,
        Pagination: pagination ? Pagination : undefined,
      }}
      {...props}
    />
  );
};

const NoRowsOverlay = () => {
  return (
    <Box>
      <Typography>데이터 없음</Typography>
    </Box>
  );
};
