import React from 'react'
import styled from 'styled-components'
import DrilldwonPlayLogo from '../../../../image/solution-logo02.png';
import DrilldownPlayImage from '../../../../image/solutions/drilldownplay/drilldownplay.png';
import Dashboard from '../../../../image/solutions/drilldownplay/dashboard.png';
import DrpSlide from '../../../../image/solutions/drilldownplay/drpslide.png';
import LinkTo from '../../../../components/units/atoms/Link';

export default function DrilldownPlay() {
  return (
    <section className='wrap'>
      <Section>
        <FlexArea>
          <FlexItem>
            <img src={DrilldownPlayImage} alt="DrilldownPlayImage" />
          </FlexItem>
          <FlexWideItem>
            <img src={DrilldwonPlayLogo} alt="DrilldwonPlayLogo" style={{ height: '40px' }} />
            <p>DRILLDOWN.play(DDP)는 파워포인트로 손쉽게 운영관리 화면을 만들 수 있는<br /> Data Orchestration Solution입니다.<br /><br />
              Fomal Data, cctv 영상, IoT 장비를 통한 sensing data, 수집 대상의 로그 등을 실시간 데이터 소스로 서버에 수집된 후 플레이어에게 시각화되어 전달됩니다.
              이를 통해 사용자는 손쉽게 현장 자료를 커스텀하여 공장의 설비상태, 운영 환경 혹은 기업의 자원에 대한 <br />
              모니터링을 할 수 있습니다. </p>
            <LinkTo targetOtherSite='http://play.drilldown.info/DrilldownPlay/#/main'>
              <LinkButton>사용해 보기</LinkButton>
            </LinkTo>
          </FlexWideItem>
        </FlexArea>
      </Section>
      <Section>
        <h3>DRLLDOWN.play</h3>
        <FlexArea>
          <img src={DrpSlide} alt="DrpSlide" style={{ width: '100%' }} />
        </FlexArea>
      </Section>
      <Section>
        <FlexArea>
          <FlexItem>
            <Title>특징</Title>
            <p>
              <b>Drilldown Slide Show </b>(Drill Map)<br />
              Slide를 3차원으로 구성하여 슬라이드쇼를 할 수 있음<br />
              기존 작성된 Slide를 활용하여 Topology Map을 쉽게 구성<br />
              전자지도 방식으로 Zoom-In, Panning을 연출
              <br /><br />
              <b> Dynamic Data Presentation (BI Dashboard)</b><br />
              실시간 데이터를 연결하여 차트, 테이블, Metric Text를 보여 주며<br /> Alarm을 표시할 수 있음<br />

            </p>
          </FlexItem>
          <FlexItem>
            <img src={Dashboard} alt="Dashboard" style={{ width: '100%' }} />
          </FlexItem>
        </FlexArea>
      </Section>

      <LinkTo target='/SOXHome/media'>
        <LinkButtonCenter>사용 방법 동영상 보기</LinkButtonCenter>
      </LinkTo>
    </section >
  )
}
const Section = styled.section`
  margin-top: 120px;
  margin-bottom: 150px;

  & h3{
    text-align: center;
    font-size: 24px;
    margin-bottom: 30px;
  }

  & p {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 8px;
    font-size: 18px;
    line-height: 30px;
  }
`;

const FlexArea = styled.div`
  display: flex;
  gap: 20px;
`;

const FlexItem = styled.div`
  flex: 4;
`;

const FlexWideItem = styled.div`
  flex: 5;
`;

const LinkButton = styled.button`
  border: none;
  height: 36px;
  width: 180px;
  background: #14457A;
  color: #fff;
  float: right;
  transform: translate(-20px, 25px);
  box-shadow: 1px 1px 4px rgba(50,50,50,0.3);
  border-radius: 3px;
`;

const LinkButtonCenter = styled.button`
  display: block;
  border: none;
  height: 36px;
  width: 250px;
  background: #14457A;
  color: #fff;
  margin: 0 auto;
  box-shadow: 1px 1px 4px rgba(50,50,50,0.3);
  border-radius: 3px;
`;

const Title = styled.div`
  width: 100%;
  border-radius: 3px;
  box-shadow: 1px 1px 4px rgba(50,50,50,0.3);
  padding: 8px;
  font-weight: 600;
`;