import React from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import EmptyArea from './EmptyArea';


interface CardProps {
  width?: number;
  image?: any;
  titleText?: string;
  subText?: string[];
  button?: any;
  header?: string;
  avatar?: any;
  route?: string;
  url?: string;
}

interface ImagCardProps extends CardProps {
  image: any;
}

interface TextCardProps extends CardProps { }

export const ImageCard = ({ ...props }: ImagCardProps) => {
  const navigate = useNavigate();
  const clickHandle = () => {

    props.route && navigate(props.route);

    props.url && window.open(props.url);
  }
  return (
    <CardDiv {...props} onClick={clickHandle}>
      <ImageSection>
        <Image src={props.image} alt="이미지" />
      </ImageSection>
      <TextSection>
        <TitleText>{props.titleText}</TitleText>
        {props.subText?.map((item: string, index: number) => <SubText key={item + index}>{item}</SubText>)}
        {props.button && <ButtonArea>{props.button}</ButtonArea>}
      </TextSection>
    </CardDiv>
  )
}

export const ImageProfileCard = ({ ...props }: ImagCardProps) => {
  return (
    <CardDiv {...props}>
      <CardHeader>
        <AvatarSection>
          {props.avatar && props.avatar}
        </AvatarSection>
        <HeaderText>{props.header}</HeaderText>
      </CardHeader>
      <ImageSection>
        <Image src={props.image} alt="이미지" />
      </ImageSection>
      <TextSection>
        <TitleText>{props.titleText}</TitleText>
        {props.subText?.map((item: string, index: number) => <SubText key={item + index}>{item}</SubText>)}
        {props.button && <ButtonArea>{props.button}</ButtonArea>}
      </TextSection>
    </CardDiv>
  )
}

export const TextCard = ({ ...props }: TextCardProps) => {
  return (
    <CardDiv {...props}>
      <TextSection>
        <TitleText>{props.titleText}</TitleText>
        <EmptyArea gap={20} />
        {props.subText?.map((item: string, index: number) => <SubText key={item + index}>{item}</SubText>)}
        {props.button && <ButtonArea>{props.button}</ButtonArea>}
      </TextSection>
    </CardDiv>
  )
}

export const TextProfileCard = ({ ...props }: TextCardProps) => {
  return (
    <CardDiv {...props}>
      <CardHeader>
        <AvatarSection>
          {props.avatar && props.avatar}
        </AvatarSection>
        <HeaderText>{props.header}</HeaderText>
      </CardHeader>
      <TextSection>
        <TitleText>{props.titleText}</TitleText>
        <EmptyArea gap={5} />
        {props.subText?.map((item: string, index: number) => <SubText key={item + index}>{item}</SubText>)}
        {props.button && <ButtonArea>{props.button}</ButtonArea>}
      </TextSection>
    </CardDiv>
  )
}
const CardDiv = styled.div`
  position: relative;
  min-height: 150px;
  box-shadow: 0 0 8px rgba(0,0,0,0.2);
  border-radius: 5px;
  margin: 20px;
  overflow: hidden;
`;

const CardHeader = styled.div`
  padding: 10px;
  min-height: 50px;
  line-height: 40px;
`;

const AvatarSection = styled.div`
  float: left;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
  margin-right: 10px;
`;

const HeaderText = styled.h3`
  padding: 0;
  font-size: 18px;
`;

const ImageSection = styled.div`
  padding: 0;
`;

const Image = styled.img`
  width: 100%;
  height: 200px;
  overflow: hidden;
`;

const TextSection = styled.div`
  padding: 5px 10px 20px 10px;
  min-height: 90px;
`;


const TitleText = styled.h3`
  padding: 0;
  font-size: 16px;
  margin-bottom: 10px;
  max-height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

`;

const SubText = styled.p`
  line-height: 20px;
  font-size: 16px;
`;

const ButtonArea = styled.div`
  position: absolute;
  bottom: 10px; right: 10px;
  cursor: pointer;
`;