import React, { useState } from 'react'
import styled from 'styled-components';


import { Map, MapMarker } from 'react-kakao-maps-sdk';

const { kakao } = window;

const Mapui = () => {

  return (
    <>
      <Map
        center={{ lat: 37.624000, lng: 127.060687 }}
        style={{ width: "100%", height: "100%" }}
      >
        <MapMarker position={{ lat: 37.624000, lng: 127.060687 }}>
        </MapMarker>
      </Map>
    </>
  )
}

export default Mapui;
