import React, { useState } from 'react'
import styled from 'styled-components';
import Button from '@/components/units/atoms/Button';
import Line from '@/components/units/atoms/Line';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { noticeAPI } from '@/api/notice';
import { fileAPI } from '@/api/file';
import { ConfirmMsg } from '@/components/units/atoms/Dialog';

export default function NoticeContents() {
  const navigate = useNavigate();

  const [notice, setNotice] = useState<any>();

  const param = useParams();
  const seq = param['*'];

  const getNotice = async () => {
    const ret: any = await noticeAPI.getNotice(seq);
    ret[0].result === 'success' && setNotice(ret[0].resultMap);
  }

  const deleteNotice = async () => {
    const ret: any = await noticeAPI.deletePost({ seq });
    ret[0].result === 'success' && navigate('/SOXHome/board/notice/list');
  }

  useEffect(() => {
    getNotice();
  }, [])


  return (
    <>
      <Container className='wrap'>
        <h3>게시글 보기</h3>
        <Line border={2}></Line>
        <Row>
          <div style={{ flex: 1, padding: '0 20px' }}>제목</div>
          <div style={{ flex: 9 }}>{notice !== undefined ? notice.title : ''}</div>
        </Row>
        <Line border={1} color="#ccc"></Line>
        <Row>
          <div style={{ flex: 1, padding: '0 20px' }}>작성자</div>
          <div style={{ flex: 9 }}>관리자</div>
        </Row>
        <Line border={1} color="#ccc"></Line>
        <FileContaner>
          <ul>
            {
              notice !== undefined && notice.file.map((item: any) => {
                const href = '/SOXHome/api/file/download.do?uuid=' + item.uuid
                return <li key={item.uuid}>
                  <a href={href}>{item.name}</a>
                </li>
              })
            }
          </ul>
        </FileContaner>
        <ContentContainer>
          {notice !== undefined && <div dangerouslySetInnerHTML={{ __html: notice.content }} />}
          {
            notice !== undefined && notice.file.map((item: any) => {
              console.log(item);
              return <div key={item.uuid}>
                <img src={item.path + '/' + item.stored_name} alt={item.name} />
              </div>
            })
          }
        </ContentContainer>
        <Line border={2}></Line>
      </Container>
      <ButtonContainer>
        <Button text={"목록"} onClick={() => navigate('/SOXHome/board/notice/list')} width={65}></Button>
        {sessionStorage.getItem('permission') === 'admin-sox' && <ConfirmMsg button={'삭제'} open={false} text="삭제하시겠습니까?" handleEvent={deleteNotice} />}
      </ButtonContainer>
    </>
  )
}

const Container = styled.div`
  padding-top: 100px;
`;

const Row = styled.div`
  display: flex;
  height: 30px;
  line-height: 30px;
`;

const FileContaner = styled.div`
  background: #ededed;
  height: 70px;
  overflow: auto;
  padding: 10px 20px;
`;

const ContentContainer = styled.div`
  padding: 10px;
  height: 500px;
`;

const ButtonContainer = styled.div`
  width: 150px;
  display: flex;
  gap: 10px;
  margin: 30px auto;
`;