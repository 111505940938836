import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { userStore } from '../../../stores/state/UserStore';
import Button from '../atoms/Button';

interface CheckInfo {
  text: string;
  topInputTitle: string;
  bottomInputTitle: string;
  handleClose: () => void;
}

export default function CheckAdmin({ ...props }: CheckInfo) {
  const idRef = useRef<HTMLInputElement>(null);
  const pwRef = useRef<HTMLInputElement>(null);



  const clickHandle = () => {
    login();
  }

  const login = async () => {
    if (idRef.current?.value !== undefined && pwRef.current?.value !== undefined) {

      const res: any = await userStore.login({
        id: idRef.current?.value,
        password: pwRef.current?.value
      });

      if (res.result === 'success') {
        sessionStorage.setItem('permission', 'admin-sox');
        window.location.reload();
      } else {
        alert('아이디와 비밀번호가 일치하지 않습니다.');
        props.handleClose();
      };


    }
  }



  return (
    <Container>
      <InfoText>{props.text}</InfoText>
      <InputText>{props.topInputTitle}</InputText>
      <Input type="text" name="id" ref={idRef} />
      <InputText>{props.bottomInputTitle}</InputText>
      <Input type="password" name='password' ref={pwRef} />
      <ButtonContainer>
        <Button text={'확인'} onClick={() => clickHandle()}></Button>
      </ButtonContainer>
    </Container>
  )
}

const Container = styled.div`
  padding: 20px;
  background: #fff;
  width: 530px;
  height: 280px;
`;

const InfoText = styled.div`
  font-size: 17px;
  text-align: center;
`;

const InputText = styled.div`
  text-align: left;
  margin-top: 15px;
`;
const Input = styled.input`
  width: 100%;
  height: 35px;
  padding: 5px;
  margin-top: 3px;
`;
const ButtonContainer = styled.div`
  margin-top: 40px;
  width: 100%;
`;