import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';


interface LinkProps {
  target?: string;
  targetOtherSite?: string;
  color?: string;
  children: any;
}

export default function LinkTo({ ...props }: LinkProps) {
  const navigate = useNavigate();
  const handleClick = (event: React.SyntheticEvent) => {
    event.preventDefault()
    props.target && navigate(props.target);

    props.targetOtherSite && window.open(props.targetOtherSite);
  }

  return (
    <Link onClick={handleClick} color={props.color} >{props.children}</Link>
  );
}

const Link = styled.div`
  margin: 0;
  cursor: pointer;
  display: inline;
  color: ${props => props.color ? props => props.color : '#333'};
`;

