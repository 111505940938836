import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@/components/units/atoms/Button';
import Line from '@/components/units/atoms/Line';
import { useRef } from 'react';
import { noticeStore } from '@/stores/state/NoticeStore';
import EditorComponent from '@/components/units/atoms/Editor';
import { useNavigate } from 'react-router-dom';

export default function NoticeWrite() {

  const titleRef = useRef<HTMLInputElement>(null);

  const fileInput: any = document.getElementsByClassName("input-image-file");

  const navigate = useNavigate();

  const [fileArr, setFileArr] = useState<any[]>([]);
  const [inputFileArr, setInputFIleArr] = useState<any[]>([]);

  const [content, setContent] = useState('');

  const handleChange = (content: any) => {
    setContent(content);
  }


  const handleFileUpload = async (e: any) => {
    let formData = new FormData();
    const { files } = e.target;
    formData.append('file', files[0]);
    const result: any = await noticeStore.fileUpload(formData);
    setFileArr([...fileArr, result.resultMap])
  }

  const handleFileUploadEditor = async (e: any) => {
    let formData = new FormData();
    const { files } = e.target;
    formData.append('file', files[0]);
    const result: any = await noticeStore.fileUpload(formData);
    setInputFIleArr([...inputFileArr, result.resultMap])
  }

  const handleSubmit = async () => {
    const res: any = await noticeStore.addNotice({
      title: titleRef.current?.value,
      content: content,
      file: fileArr
    });
    res.result === 'success' ? navigate('/SOXHome/board/notice/list') : alert('게시글 작성 실패');
  }

  const imgHandler = () => {
    // fileRef.click();
    console.log(fileInput[0]);
    fileInput[0].click();
  }


  return (
    <>
      <Container className='wrap'>
        <h3>게시글 작성</h3>
        <Line border={2}></Line>
        <Row>
          <div style={{ flex: 1, padding: '0  19px' }}>제목</div>
          <div style={{ flex: 9 }}>
            <InputText type="text" ref={titleRef} />
          </div>
        </Row>
        <Line border={1} color="#ccc"></Line>
        <Row>
          <div style={{ flex: 1, padding: '0 20px' }}>파sssssssssss일</div>
          <div style={{ flex: 9 }}>
            <input type="file" onChange={(e) => handleFileUpload(e)} />
          </div>
        </Row>
        <FileContainer>
          {
            fileArr.map((file: any) => <span style={{ marginRight: '15px' }}>{file.original_name}</span>)
          }
        </FileContainer>
        <Line border={1} color="#ccc"></Line>
        <ContentContainer>
          <EditorComponent content={content} onChage={handleChange} imgHandler={imgHandler} />
          <input type="file" className='input-image-file' onChange={(e) => handleFileUploadEditor(e)} hidden />
          {/* <TextArea ref={contentRef} ></TextArea> */}
        </ContentContainer >
        <Line border={2}></Line>
      </Container>
      <ButtonContainer>
        <Button text={"작성"} onClick={handleSubmit}></Button>
        <Button text={"취소"} onClick={() => navigate('/SOXHome/board/notice/list')}></Button>
      </ButtonContainer>
    </>
  )
}



const Container = styled.div`
  padding-top: 100px;
`;

const Row = styled.div`
  display: flex;
  height: 30px;
  line-height: 30px;
`;

const ContentContainer = styled.div`
  padding: 10px;
  height: 500px;
`;

const ButtonContainer = styled.div`
  width: 15vw;
  display: flex;
  gap: 10px;
  margin: 30px auto;
`;

const InputText = styled.input`
  width: 100%;
  border: 1px solid #ccc;
  height: 100%;
  border-radius: 3px;
  padding: 3px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 5px;
  height: 100%;
  resize: none;
  border: 1px solid #ccc;
  border-radius: 3px;
`

const FileContainer = styled.div`
  padding: 10px;
  background: #eee;
  width: 100%;
  height: 50px;
  overflow: auto;
`;