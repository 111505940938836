import styled from "styled-components";

import React from 'react'

export default function CommonCssTemplate() {
  return (
    <div>CommonCssTemplate</div>
  )
}


export const SampleContainer = styled.div`
  margin: 10px auto;
  border: 1px solid #999;
  border-top: 5px solid #999;
  padding: 30px 50px 50px 50px;
  border-radius: 5px;
  `;

export const FlexContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  `;

export const FlexItem = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;
  border-radius: 10px;
  `;

