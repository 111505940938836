import React from 'react'
import styled from 'styled-components'
import StayOnboxLogo from '../../../../image/solution-logo01.png';
import StayOnboxImage from '../../../../image/solutions/stayonbox/stayonbox.png';
import StayOnboxStagImage from '../../../../image/solutions/stayonbox/stayonbox-stag.png';
import StayOnboxIndoorImage from '../../../../image/solutions/stayonbox/stayonbox-indoor.png';
import Edge from '../../../../image/solutions/stayonbox/edge.png';
import LinkTo from '../../../../components/units/atoms/Link';

export default function StayOnBox() {
  return (
    <section className='wrap'>
      <Section>
        <FlexArea>
          <FlexItem>
            <img src={StayOnboxImage} alt="StayOnboxImage" />
          </FlexItem>
          <FlexWideItem>
            <img src={StayOnboxLogo} alt="StayOnboxLogo" style={{ height: '40px' }} />
            <p>대인 및 대물에 대한 위치를 응용하려는 니즈는 많으나 적용할 물리적 환경이 복잡하고 <br />
              다양함에 따라 도입 시 많은 투자가 필요합니다. STAYON.box는 소프트웨어 플랫폼을  <br />
              통해 다양한 고객의 환경과 니즈를 충족하면서도 ROI를 만족시킬 위치 솔루션을  <br />
              제공할 수 있습니다.
              <br /><br />
              STAYON.box STag은 긴급구조 상황에서 구조자의 위치를 빠르고 정확하게 제공하는 <br />
              복합측위 플랫폼입니다. 긴급상황에서 수집된 GPS,LTE,WiFi,BLE 신호를 복합적으로 <br />
              분석해서 구조자의 위치를 정확하게 찾아 재난과 위협으로 부터 보호해 줍니다.
              <br /><br />
              STAYON.box Indoor는 WiFi 기술을 이용하여 측위 및 위치인증을 하는 솔루션입니다. <br />
              인가된 디바이스의 위치를 찾는 것은 물론 방문객에 대한 데이터를 수집하여 집계하는 <br />
              서비스를 제공합니다. 또한, STAYON.box Indoor 위치인증 기능을 이용하면 허가된  <br />
              위치에서만 컨텐츠 열람이나 서비스를 이용하도록 제어할 수 있습니다. (물리보안)</p>
            <LinkTo targetOtherSite='http://sox.iptime.org:30080/NeoCopleinService'>
              <LinkButton>사용해 보기</LinkButton>
            </LinkTo>
          </FlexWideItem>
        </FlexArea>
      </Section>
      <Section>
        <h3>STAYON.box Stag</h3>
        <FlexArea>
          <FlexWideItem>
            <img src={StayOnboxStagImage} alt="StayOnboxImage" style={{ width: '100%' }} />
          </FlexWideItem>
          <FlexItem>
            <Title>StayON.box Stag는?</Title>
            <p>- 복합 측위를 위한 LTE-WiFi-BLE 신호 데이터베이스
              <br />
              - 지도 상의 spot을 수신된 무선신호 데이터로 Tagging 한 지도 플랫폼
              <br />
              - GPS, WiFi, LTE, BLE 신호 데이터 조합을 비교해서 위치를 찾음
              <br />
              - 경찰, 소방에 적용될 긴급구조용 측위 데이터베이스 형식 및  <br />&nbsp;&nbsp;&nbsp;인터페이스 지원</p>
            <Title>특징</Title>
            <p>
              * 어느 장소나 균일한 측위 품질<br />
              - 통신3사 복합 측위 : 50m이내<br />
              <b>- 복합 측위 정확도 : 25m 이내</b><br /><br />
              -위치확인 성공률: 95% 이상
            </p>
          </FlexItem>
        </FlexArea>
      </Section>
      <Section>
        <h3>STAYON.box Indoor</h3>
        <FlexArea>
          <FlexWideItem>
            <img src={StayOnboxIndoorImage} alt="StayOnboxIndoorImage" style={{ width: '100%' }} />
          </FlexWideItem>
          <FlexItem>
            <Title>STAYON. box Indoor는?</Title>
            <p>- 스마트폰 무선신호를 센싱하여 사람들의 접근, 이동정보 등을 획득
              <br />
              - 방문빈도, 체류시간, 재방문 등 집계
              <br />
              - Edge 자체적으로 데이터를 집계 처리 함 (인텔리전스)
              <br />
              - Edge를 멀티로 운영할 때 실내에서 대상에 대한 정밀 측위
              가능</p>
          </FlexItem>
        </FlexArea>
      </Section>
      <LinkTo target='/SOXHome/media'>
        <LinkButtonCenter>사용 방법 동영상 보기</LinkButtonCenter>
      </LinkTo>
    </section >
  )
}
const Section = styled.section`
  margin-top: 120px;
  margin-bottom: 150px;

  & h3{
    text-align: center;
    font-size: 24px;
    margin-bottom: 30px;
  }

  & p {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 8px;
    font-size: 18px;
    line-height: 30px;
  }
`;

const FlexArea = styled.div`
  display: flex;
  gap: 20px;
`;

const FlexItem = styled.div`
  flex: 4;
`;

const FlexWideItem = styled.div`
  flex: 5;
`;

const LinkButton = styled.button`
  border: none;
  height: 36px;
  width: 180px;
  background: #14457A;
  color: #fff;
  float: right;
  transform: translate(-20px, 25px);
  box-shadow: 1px 1px 4px rgba(50,50,50,0.3);
  border-radius: 3px;
`;

const Title = styled.div`
  width: 100%;
  border-radius: 3px;
  box-shadow: 1px 1px 4px rgba(50,50,50,0.3);
  padding: 8px;
  font-weight: 600;
`;

const LinkButtonCenter = styled.button`
  display: block;
  border: none;
  height: 36px;
  width: 250px;
  background: #14457A;
  color: #fff;
  margin: 0 auto;
  box-shadow: 1px 1px 4px rgba(50,50,50,0.3);
  border-radius: 3px;
`;