import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import Style from "@/boardPreview.module.css";
import { Autoplay } from "swiper";
import styled from "styled-components";

export default function BoardPreview({ ...props }: any) {
  return (
    <BoardPreviewContainer>
      <Swiper
        spaceBetween={5}
        direction={"vertical"}
        loop={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className={Style['mySwiper']}
        style={{ width: '100%' }}
      >
        {
          props.boardList.map((item: any) => <SwiperSlide key={item.seq} className={Style['swiper-slide']}>{item.title}</SwiperSlide>)
        }
      </Swiper>
    </BoardPreviewContainer>
  );
}

const BoardPreviewContainer = styled.div`
  position: sticky;
  top: 59px;
  z-index: 10;
  background: #000;
  color: #fff;
  height: 40px;
  text-align: center;
  font-size: 22px;
  box-shadow:  0 3px 5px rgba(100,100,100,0.6);
  text-align: center;
  margin: 0 auto;
`;