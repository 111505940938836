import { API } from '.';



export const userAPI = {
  setAdmin: <DATA, BODY>(body: BODY) => {
    return API.post<DATA, BODY>('/SOXHome/api/board/setAdminMode.do', body);
  },
  removeAdmin: <DATA>() => {
    return API.get<DATA>('/SOXHome/api/board/logout.do');
  }
}