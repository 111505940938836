import { fileAPI } from '@/api/file';
import { action, makeObservable, observable } from 'mobx';
import { noticeAPI } from '../../api/notice';
import { NoticeListModel } from '../models/NoticeListModel';
import { NoticeModel } from '../models/NoticeModel';

export interface NoticeStoreInfo {
  notice: NoticeModel;
  noticeList: NoticeListModel;
}

export class NoticeStore implements NoticeStoreInfo {
  notice: NoticeModel | any;
  noticeList: NoticeListModel | any;

  constructor() {
    this.notice = {};
    this.noticeList = [];
    this.getNoticeList();

    makeObservable(this, {
      notice: observable,
      noticeList: observable,
      getNoticeList: action,
      getNotice: action,
      fileUpload: action,
      addNotice: action,
      deleteNotice: action,
    })
  }

  getNoticeList = async () => {
    const [ret, err]: any = await noticeAPI.getNoticeList<NoticeListModel>();
    if (err) {
      return 'err';
    } else {
      if (ret) {
        this.noticeList = ret.resultList;
        return ret;
      }
    }
  }
  getNotice = async (query: any) => {
    const [ret, err]: any = await noticeAPI.getNotice<NoticeModel>(query);

    if (err) {
      return 'err';
    } else {
      if (ret) {
        this.notice = ret.resultMap;

        return ret.resultMap;
      }
    }
  }
  fileUpload = async (file: any) => {
    const [ret, err] = await fileAPI.uploadFile(file);
    if (err) {
      return 'err';
    } else if (ret) {
      return ret;
    }
  }
  addNotice = async (query?: any) => {
    const [ret, err] = await noticeAPI.addPost(query);
    if (err) {
      return 'err';
    } else if (ret) {
      return ret;
    }
    this.getNoticeList();
  }


  deleteNotice = async (params?: any) => {
    const [ret, err] = await noticeAPI.deletePost(params);
    if (err) {
      return 'err';
    } else if (ret) {
      return 'success';
    }
    this.getNoticeList();
  }

}

export const noticeStore = new NoticeStore();

