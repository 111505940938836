import React, { useState } from 'react';
import styled from 'styled-components';
import TopImg from '../../../image/solutions/topimage.png';
import SubTopSection from '../elements/common/SubTopSection';
import DrilldownPlay from './solutions/DrilldownPlay';
import StayOnBox from './solutions/StayOnBox';
import Works from './solutions/Works';

type SolutionType = 'StayOnbox' | 'DrilldownPlay' | 'works';


export default function SolutionsPage() {
  window.scrollTo(0, 0);

  const [solution, setSolution] = useState<SolutionType>('StayOnbox');


  return (
    <>
      <SubTopSection image={TopImg} />
      <NavConatiner>
        <SuvNav className='wrap'>
          <SubNavLink onClick={() => setSolution('StayOnbox')} className={`${solution === 'StayOnbox' && 'highlight-sub-nav'}`}>STAYON.box</SubNavLink>
          <SubNavLink onClick={() => setSolution('DrilldownPlay')} className={`${solution === 'DrilldownPlay' && 'highlight-sub-nav'}`}>DRILLDOWN.play</SubNavLink>
          <SubNavLink onClick={() => setSolution('works')} className={`${solution === 'works' && 'highlight-sub-nav'}`}>Works</SubNavLink>
        </SuvNav>
      </NavConatiner>
      {solution === 'StayOnbox' ? <StayOnBox /> : solution === 'DrilldownPlay' ? <DrilldownPlay /> : <Works />}
    </>
  )
}

const NavConatiner = styled.section`
  position: sticky;
  top: 60px;
  height: 40px;
  width: 100%;
  background: #fff;
  box-shadow: 0 4px 5px rgba(100,100,100,0.2);
  z-index:10;
`;

const SuvNav = styled.div`
  display: flex;
`;

const SubNavLink = styled.div`
  flex: 1;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  font-size: 19px;
`;