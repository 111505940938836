import { GridColDef } from '@mui/x-data-grid';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { noticeStore } from '../../../../stores/state/NoticeStore';
import { DataTable } from '../../../units/atoms/DataTable';
import Button from '../../../units/atoms/Button';

export default function NoticeList() {

  const navigate = useNavigate();


  const columns: GridColDef[] = [
    { field: 'idx', headerName: '번호', flex: 1 },
    { field: 'title', headerName: '제목', flex: 12 },
    { field: 'date', headerName: '작성일', flex: 2 }
  ];

  return (
    <>
      <Category>공지사항</Category>
      <TableContainer>
        <DataTable
          pagination={true}
          getRowId={row => row.seq}
          columns={columns}
          rows={noticeStore.noticeList}
          pageSize={7}
          rowsPerPageOptions={[7]}
          experimentalFeatures={{ newEditingApi: true }}
          onRowClick={(e) => navigate('/SOXHome/board/notice/content/' + e.id)} />
      </TableContainer>
      {sessionStorage.getItem('permission') && <ButtonArea>
        <Button text={'게시글 작성'} width={150} onClick={() => navigate('/SOXHome/board/notice/write')}></Button>
      </ButtonArea>}
    </>
  )
}

const Category = styled.h3`
  display: inline-block;
  margin-bottom: 10px;
`;

const TableContainer = styled.h3`
  height: 480px;

  & .MuiDataGrid-cellContent {
    cursor: pointer;
  }
`;
const ButtonArea = styled.div`
  text-align: right;
  margin-top: 30px;
`;