import { API } from '.';



export const qnaAPI = {
  getQuestionList: <DATA>() => {
    return API.get<DATA>('/SOXHome/api/board/getQuestionList.do');
  },
  getQuestion: <DATA>(seq: any) => {
    return API.get<DATA>('/SOXHome/api/board/getQuestion.do?seq=' + seq);
  },
  addPost: <DATA, BODY>(body: BODY) => {
    return API.post<DATA, BODY>('/SOXHome/api/board/setQuestion.do', body);
  },
  deletePost: (seq: any) => {
    return API.get('/SOXHome/api/board/deleteQuestion.do?seq=' + seq);
  },
  getComment: <DATA>(query: any) => {
    return API.get<DATA>('/SOXHome/api/board/getComment.do', query);
  },
  setComment: <DATA, BODY>(query: any) => {
    return API.post<DATA, BODY>('/SOXHome/api/board/setComment.do', query);
  },
  deleteComment: <DATA>(query: any) => {
    return API.get<DATA>('/SOXHome/api/board/deleteComment.do', query);
  }
}