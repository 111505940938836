import React from 'react'
import styled from 'styled-components';

interface SubTopSectionProps {
  image: any;
}

export default function SubTopSection({ ...props }: SubTopSectionProps) {
  return (
    <Section>
      <Image src={props.image} alt="상단이미지" />
    </Section>
  )
}

const Section = styled.div`
  height: 17vh;
  overflow: hidden;
`;


const Image = styled.img`
  width: 100%;
`;