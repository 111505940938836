import React from 'react';
import './App.css';
import Router from './routes';
import { BrowserRouter } from "react-router-dom";
import Footer from './components/views/elements/common/Footer';
import Header from './components/views/elements/common/Header';



function App() {
  return (
    <>
      <BrowserRouter >
        <Header />
        <div style={{ minHeight: '80vh' }} >
          <Router />
        </div>
        <Footer />
      </BrowserRouter >
    </>
  );
}

export default App;


