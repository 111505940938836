import React, { useRef, useState } from 'react'
import styled from 'styled-components';
import Button from '@/components/units/atoms/Button';
import Line from '@/components/units/atoms/Line';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { fileAPI } from '@/api/file';
import { qnaAPI } from '@/api/qna';
import { ConfirmMsg } from '@/components/units/atoms/Dialog';

export default function QnaContents() {
  const navigate = useNavigate();

  const [page, setPage] = useState<boolean>(false);
  const [qna, setQna] = useState<any>();

  const param = useParams();
  const seq = param['*'];

  const getNotice = async () => {
    const ret: any = await qnaAPI.getQuestion(seq);
    ret[0].result === 'success' && setQna(ret[0].resultMap);

    setPage(true);
  }

  const deletePost = async () => {
    const ret: any = await qnaAPI.deletePost(seq);
    ret[0].result === 'success' && navigate('/SOXHome/board/qna');
  }

  useEffect(() => {
    if (sessionStorage.getItem('permission') === 'admin-sox') {
      getNotice();
    } else {
      navigate('/SOXHome/board/qna');
    }
    return () => {
      sessionStorage.removeItem('qna-writer');
    }
  }, [page])


  return (
    <>
      <Container className='wrap'>
        <h3>문의내용 보기</h3>
        <Line border={2}></Line>
        <Row>
          <div style={{ flex: 1, padding: '0 20px' }}>작성자 이름</div>
          <div style={{ flex: 9 }}>{qna !== undefined ? qna.name : ''}</div>
        </Row>
        <Line border={1} color="#ccc"></Line>
        <Row>
          <div style={{ flex: 1, padding: '0 20px' }}>작성자 메일</div>
          <div style={{ flex: 9 }}>{qna !== undefined ? qna.email : ''}</div>
        </Row>
        <FileContaner>
          <ul>
            {
              qna !== undefined && qna.file.map((item: any) => {
                const href = '/SOXHome/api/file/download.do?uuid=' + item.uuid
                return <li key={item.uuid}>
                  <a href={href}>{item.name}</a>
                </li>
              })
            }
          </ul>
        </FileContaner>
        <ContentContainer>
          <pre>
            {qna !== undefined && qna.content.replaceAll("<br>", "\n")}
          </pre>
        </ContentContainer>
        <CommentContainer>
        </CommentContainer>
        <Line border={2}></Line>
      </Container>
      <ButtonContainer>
        <Button text={"목록"} onClick={() => navigate('/SOXHome/board/qna')} width={60}></Button>
        <ConfirmMsg button={'삭제'} open={false} text="삭제하시겠습니까?" handleEvent={deletePost} />
      </ButtonContainer>
    </>
  )
}

const Container = styled.div`
  padding-top: 100px;
`;

const Row = styled.div`
  display: flex;
  height: 30px;
  line-height: 30px;
`;

const FileContaner = styled.div`
  background: #ededed;
  height: 70px;
  overflow: auto;
  padding: 10px 20px;
`;

const ContentContainer = styled.div`
  padding: 10px;
  height: 500px;
`;

const ButtonContainer = styled.div`
  width: 150px;
  display: flex;
  gap: 10px;
  margin: 30px auto;
`;

const CommentContainer = styled.div`
  width: 100%;
  margin: 30px auto;

`;