import React from 'react';
import styled from 'styled-components';
import Mapui from '../../../components/units/atoms/Map';
import Line from '../../../components/units/atoms/Line';
import SOXLogo from '../../../image/SoxLogo.png';


export default function CompanyPage() {
  return (
    <>
      <FirstSection className='wrap'>
        <LogoDiv>
          <LogoImg src={SOXLogo} alt="logo" />
        </LogoDiv>
        <IntroduceDiv>
          에스오엑스는 다년간 위치/공간정보 프로젝트 및 연구 개발을 수행한 기술력을 바탕으로
          비 GPS 정밀측위 및 위치인증 솔루션,<br />
          정밀 Fused Positioning 기술을 개발하였습니다.<br />
          <br />
          위치기술과 데이터 시각화 기술을 바탕으로 고객이 필요로 하는 IT서비스와 솔루션을 제공하는 기업으로 정진해 나가겠습니다.<br />
          <br />
          김 성 용 대표
        </IntroduceDiv>
      </FirstSection>
      <SecondSection className='wrap'>
        <FlexAnnualBox>
          <Year>2018</Year>
          <Line />
          <ul>
            <li>
              <Month>09</Month>(주)에스오엑스 설립
            </li>
          </ul>
        </FlexAnnualBox>
        <FlexAnnualBox>
          <Year>2019</Year>
          <Line />
          <ul>
            <li>
              <Month>01</Month>실내위치기술 용역 수행 <br />
              (한국전자통신연구원)
            </li>
            <li>
              <Month>02</Month>성북구 중장년기술창업센터 <br />
              입주기업 선정
            </li>
            <li>
              <Month>09</Month>KISA 위치기반서비스 <br />
              공모전 입상
            </li>
          </ul>
        </FlexAnnualBox>
        <FlexAnnualBox>
          <Year>2020 </Year>
          <Line />
          <ul>
            <li>
              <Month>03</Month>서울 캠퍼스타운<br /> SNK-VITAMIN
              입주기업 선정
            </li>
            <li>
              <Month>04</Month>ETRI 긴급구조 정밀측위기술<br />
              (LTE/WiFi복합측위) 개발
            </li>
            <li>
              <Month>08</Month>LH 토지주택연구원<br />
              스마트시티 Use Case 검증 시나리오 및 검증 포털 개발<br />
            </li>
            <li>
              <Month>05</Month>TCB(투자용기술평가등급)<br />
              평가 우수등급
            </li>
            <li>
              <Month>12</Month>중소벤처기업부 디딤돌과제 선정
            </li>
          </ul>
        </FlexAnnualBox>
        <FlexAnnualBox>
          <Year>2021</Year>
          <Line />
          <ul>
            <li>
              <Month>02</Month>기업부설 연구소 인정
            </li>
            <li>
              <Month>04</Month>ETRI 긴급구조 정밀측위 기술
              고도화 개발
            </li>
            <li>
              <Month>06</Month>벤처기업 선정
            </li>
            <li>
              <Month>11</Month>긴급구조 정밀측위 기술
              (LTE.ViFi 복합측위)
              과기부 장관 시연
            </li>
            <li>
              <Month>12</Month>STAYON.box Indoor
              TTA<br />
              (한국정보통신기술협회) 시험 인증
            </li>
          </ul>
        </FlexAnnualBox>
        <FlexAnnualBox>
          <Year>2022</Year>
          <Line />
          <ul>
            <li>
              <Month>04</Month>부천시 ITS 유고상황
              운영관리시스템 개발 계약
            </li>
            <li>
              <Month>05</Month>ETRI 측위용 멀티소스 기반 측위
              DB갱신 및 이동통신사 연계 측위
              실증 도구 개발 예약
            </li>
            <li>
              <Month>06</Month>직접생산확인증명
              (소프트웨어엔지니어링, 데이터서비스)
            </li>
            <li>
              <Month>08</Month>광운대창업보육센터 입주기업 선정
            </li>
            <li>
              <Month>08</Month>제주JDC 면세점
              STAYON.box Indoor 공급
            </li>
            <li>
              <Month>09</Month>디엠씨코넷 빅데이터 과정 강의
              데이터 시각화 솔루션
              Driildown.play 서비스
            </li>
            <li>
              <Month>09</Month>경찰청 폴리스랩
              치안현장용 측위 프로젝트 계약
            </li>
          </ul>
        </FlexAnnualBox>
      </SecondSection>
      <ThirdSection className='wrap'>
        <MapSection>
          <h2>Contact Us</h2>
          <MapContainer>
            <Mapui />
          </MapContainer>
        </MapSection>
        <AddrSection>
          <FlexItem>
            <h3>주소</h3>
            <p>서울시 노원구 석계로18길 23, 삼능스페이스향 301호 (우)01886</p>
          </FlexItem>
          <FlexItem>
            <h3>연락처</h3>
            <p>
              TEL : (82)2-494-4707 <br />
              E-MAIL : soxcom@naver.com
            </p>
          </FlexItem>
        </AddrSection>
      </ThirdSection>
    </>
  )
}

const FirstSection = styled.section`
  display: flex;
  padding: 100px 0 100px 0;
  align-items: center;
`;

const LogoDiv = styled.div`
  flex: 1;
  align-items: center;
`;

const IntroduceDiv = styled.div`
  flex: 3;
  font-size: 17px;
`;

const LogoImg = styled.img`
  height: 80px;
`;

const SecondSection = styled.section`
  display: flex;
  padding: 100px 0 100px 0;
  align-items: center;
`;

const FlexAnnualBox = styled.div`
  flex: 1;
  height: 650px;

  & li {
    margin-bottom: 30px;
    padding-right: 15px;
  }
`;

const Year = styled.span`
  padding: 15px;
  font-size: 21px;
  font-weight: bold;
`;

const Month = styled.span`
  color: #14457A;
  font-style: italic;
  margin-right: 10px;
  font-size: 20px;
`;

const ThirdSection = styled.section`
`;

const MapSection = styled.div`
  text-align: center;
`;

const MapContainer = styled.div`
  height: 500px;
  background: #888;
  margin: 30px 0 50px 0;
`;

const AddrSection = styled.div`
  display: flex;
`;

const FlexItem = styled.div`
  flex: 1;
`;