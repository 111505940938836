import { Button as MuiButton, ButtonProps as _ButtonProps, css, styled, SvgIconProps, Theme } from '@mui/material';
import React from 'react'

export interface ButtonProps extends _ButtonProps {
  theme?: Theme;
  text: string | any;
  background?: string;
  textColor?: string;
  width?: number;
  round?: boolean;
  image?: any;
}

export default function Button({ ...props }: ButtonProps) {
  return (
    <>
      <StyledButton {...props}  >{props.text}</StyledButton>
    </>
  )
}

const StyledButton = styled(({ ...props }: ButtonProps) => (
  <MuiButton {...props} />
))(({ ...props }) => {
  return css`
    background:${props.background ? props.background : '#588ABC'};
    ${props.image && `background-image : ${props.image}`}
    color:${props.textColor ? props.textColor : '#fff'};
    border: none;
    width:${props.width ? props.width + 'px' : '100%'};
    ${props.variant === 'outlined' && `border : 1px solid ${props.textColor};`}
    ${props.variant === 'text' && `background : transparent;`}
    ${props.round === true && 'border-radius: 30px;'}
  &:hover{
    background:${props.variant !== 'text' ? (props.background ? props.background : '#588ABC') : 'transparent'};
    opacity: 0.8;
    transition: 0.2s;
    border: ${props.variant === 'outlined' ? `1px solid ${props.textColor}` : 'none'};
`
});

