import React, { useState } from 'react'
import styled from 'styled-components';
import TopImage from '@/image/board.png';
import SubTopSection from '@/components/views/elements/common/SubTopSection';
import QnaList from '@/components/views/board/qna/QnaList';
import QnaWrite from '../board/qna/QnaWrite';
import { useNavigate } from 'react-router-dom';


export default function QnaPage() {

  window.scrollTo(0, 0);
  const navigate = useNavigate();
  window.scrollTo(0, 0);

  return (
    <>
      <SubTopSection image={TopImage} />
      <NavConatiner>
        <SuvNav className='wrap'>
          <SubNavLink onClick={() => navigate('/SOXHome/board/notice/list')}>Notice</SubNavLink>
          <SubNavLink onClick={() => { navigate('/SOXHome/board/qna') }} className="highlight-sub-nav">QnA</SubNavLink>
        </SuvNav>
      </NavConatiner>
      <Container className='wrap'>
        {sessionStorage.getItem('permission') === 'admin-sox' ? <QnaList /> : <QnaWrite />}
      </Container>
    </>
  )
}


const SubNavLink = styled.div`
  flex: 1;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  font-size: 19px;
`;

const Container = styled.div`
  margin-bottom: 100px;
`;


const NavConatiner = styled.section`
  position: sticky;
  top: 60px;
  height: 40px;
  width: 100%;
  background: #fff;
  box-shadow: 0 4px 5px rgba(100,100,100,0.2);
  z-index:1;
`;

const SuvNav = styled.div`
  display: flex;
`;