import React from 'react'
import styled from 'styled-components';
import SSF from '../../../image/infra/ssf.png';
import SFF from '../../../image/infra/sff.png';
import DEVOPS from '../../../image/infra/devops.png';
import SoxDevOps from '../../../image/infra/sox-devops.jpg';

export default function InfraPage() {
  return (
    <>
      <SubTitle>Infra</SubTitle>
      <FlexContainer className='wrap'>
        <FlexItem>
          <InfraTitle>SOX Service Framework (SSF)</InfraTitle>
          <p>Web Application 서버와 클라이언트를 빠르게 구축할 수 있는
            JAVA 기반의 application Framework<br /><br /></p>
          <h4>SSF의 특징</h4>
          <ul>
            <li>&nbsp;&nbsp;· 소스 구성이 심플함</li>
            <li>&nbsp;&nbsp;· 개발 시 개발 도구에서 소스를 다룰 때 가벼움</li>
            <li>&nbsp;&nbsp;· 디버깅 시 빠른 반응</li>
            <li>&nbsp;&nbsp;· IoT장비에 탑재 가능</li>
            <li>&nbsp;&nbsp;· MVC 패턴을 지원하지만 비교적 단순한 구조</li>
            <li>&nbsp;&nbsp;· 코드의 가독성이 좋아 유지보수가 용이함</li>
            <li>&nbsp;&nbsp;· 다양한 Util 클래스와 함수 제공으로 사용 빈도가 높은 기능을 빠르게 코딩</li>
          </ul>
          <br />
          <p>현재 8개 이상의 프로젝트에 적용 중</p>
        </FlexItem>
        <FlexItem>
          <Image src={SSF} alt="SSF" />
        </FlexItem>
      </FlexContainer>
      <FlexContainer className='wrap'>
        <FlexItem>
          <Image src={SFF} alt="SFF" />
        </FlexItem>
        <FlexItem>
          <InfraTitle>SOX Frontend Framework (SFF)</InfraTitle>
          <p>SPA를 빠르게 개발할 수 있는 React 기반의 Frontend Framework<br /><br /></p>
          <h4>SFF의 특징</h4>
          <ul>
            <li>&nbsp;&nbsp;· 사용빈도가 높은 컴포넌트로 구성</li>
            <li>&nbsp;&nbsp;· SPA와 모바일에 최적화 된 프레임웍</li>
            <li>&nbsp;&nbsp;· 스토리북을 통해 사용 및 응용방법 제공</li>
            <li>&nbsp;&nbsp;· 프론트엔드 개발에 높은 생산성 제공</li>
          </ul>
          <br />
        </FlexItem>
      </FlexContainer>
      <FlexContainer className='wrap'>
        <FlexItem>
          <InfraTitle>DevOps</InfraTitle>
          <p>SOX DevOps는 에스오엑스의 개발철학을 <br />
            담고 있는 체계로 안정적이고 빠르게 서비스와<br />
            솔루션을 개발/배포할 수 있습니다.<br /><br /></p>
          <h4>특징</h4>
          <ul>
            <li>&nbsp;&nbsp;· 의례적이거나 뻔한 작업은 자동화 </li>
            <li>&nbsp;&nbsp;· 장애 및 오류에 대한 빠른 추적</li>
            <li>&nbsp;&nbsp;· SSF/SFF 적용으로 개발자는 서비스 개발에만 집중</li>
          </ul>
          <br />
        </FlexItem>
        <FlexWideItem>
          <div style={{ transform: "translateY(-80px)" }}>
            <Image src={SoxDevOps} alt="DEVOPS" />
          </div>
        </FlexWideItem>
      </FlexContainer>
    </>
  )
}

const FlexContainer = styled.div`
  display: flex;
  margin-bottom: 180px;
`;

const FlexItem = styled.div`
  flex: 1;
  padding: 10px;

  & li,
  & p {
  line-height: 30px;
  font-size: 18px;
  }

  & h4{
    font-size: 18px;
    margin-bottom: 10px;
  }

`;

const FlexWideItem = styled.div`
  flex: 2.5;
  padding: 10px;

  & li,
  & p {    
    line-height: 23px;
  }
`;


const InfraTitle = styled.h4`
  font-size: 20px;
  margin-bottom: 20px;
`;

const SubTitle = styled.h3`
  text-align: center;
  font-size: 24px;
  margin: 50px 0 50px 0;
`;

const Image = styled.img`
  width: 100%;
`;