import { API } from '.';



export const noticeAPI = {
  getNoticeList: <DATA>() => {
    return API.get<DATA>('/SOXHome/api/board/getNoticeList.do');
  },
  getNotice: <DATA>(seq: any) => {
    return API.get<DATA>('/SOXHome/api/board/getNotice.do?seq=' + seq);
  },
  addPost: <DATA, BODY>(body: BODY) => {
    return API.post<DATA, BODY>('/SOXHome/api/board/setNotice.do', body);
  },

  deletePost: <DATA>(query: any) => {
    return API.get<DATA>('/SOXHome/api/board/deleteNotice.do', query);
  },
}