import { GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { DataTable } from '@/components/units/atoms/DataTable';
import { qnaStore } from '@/stores/state/QnaStore';

export default function QnaList() {
  const navigate = useNavigate();
  const [page, setPage] = useState<boolean>(false);



  const getPost = async () => {
    await qnaStore.getQnaList();
    setPage(true);
  }

  useEffect(() => {
    getPost();
  }, [page])


  const columns: GridColDef[] = [
    { field: 'idx', headerName: '번호', flex: 1 },
    { field: 'name', headerName: '작성자', flex: 2 },
    { field: 'email', headerName: 'e-mail', flex: 2 },
    { field: 'date', headerName: '작성일', flex: 2 }
  ];

  return (
    <>
      <Category>문의내용</Category>
      <TableContainer>
        <DataTable
          pagination={true}
          getRowId={row => row.seq}
          columns={columns}
          rows={qnaStore.qnaList}
          pageSize={7}
          rowsPerPageOptions={[7]}
          experimentalFeatures={{ newEditingApi: true }}
          onRowClick={(e) => navigate('/SOXHome/board/qna/' + e.id)} />
      </TableContainer>
    </>
  )
}

const Category = styled.h3`
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 30px;
`;

const TableContainer = styled.h3`
  height: 480px;

  & .MuiDataGrid-cellContent {
    cursor: pointer;
  }
`;
const ButtonArea = styled.div`
  text-align: right;
  margin-top: 30px;
`;

const GuestLayer = styled.div`
  width: 500px;
  height: 270px;
  padding: 20px;
  border-radius: 5px
`;

const Input = styled.input`
  width: 100%;
  height: 35px;
  padding: 3px;
`;