import React from 'react'
import styled from 'styled-components'
import WorksLogo from '../../../../image/solutions/works/works_logo_color 2.png';
import WorksImage from '../../../../image/solutions/works/worksImage.png';
import WorksScreenShot from '../../../../image/solutions/works/works.png';
import LinkTo from '../../../../components/units/atoms/Link';

export default function Works() {
  return (
    <section className='wrap'>
      <Section>
        <FlexArea>
          <FlexItem>
            <img src={WorksImage} alt="WorksImage" />
          </FlexItem>
          <FlexWideItem>
            <img src={WorksLogo} alt="StayOnboxLogo" style={{ height: '40px' }} />
            <p>SOX Works는 스타트업 기업들의 니즈를 파악하여 무료로 사용 할 수 있는 직군별 맞춤 솔루션을 개발하고 있습니다. <br />
              업무시간을 단축시키고, 데이터 관리를 효율적으로 할 수 있는 HR 서비스, ERP서비스 등을 확장해 나아갑니다. ​(역량진단, 기업내 설문/평가, 근태관리, 데이터 관리 등)</p>
            <LinkTo targetOtherSite='https://www.soxcorp.co.kr/survey'>
              <LinkButton>사용해 보기</LinkButton>
            </LinkTo>
          </FlexWideItem>
        </FlexArea>
      </Section>
      <Section>
        <FlexArea>
          <FlexItem>
            <img src={WorksScreenShot} alt="WorksScreenShot" style={{ width: '80%' }} />
          </FlexItem>
          <FlexItem>
            <Title>목적</Title>
            <p>
              - 기업내에서 조직원 대상으로 설문이나 평가를 실시하고
              통계데이터를 통합적으로 관리하는 서비스
              <br /><br />
              - 일과 사람에 대한 조직원의 역량진단
              <br /><br />
              - 설문 생성 및 수행, 집계
            </p>
          </FlexItem>
        </FlexArea>
      </Section>
    </section >
  )
}
const Section = styled.section`
  margin-top: 120px;
  margin-bottom: 150px;

  & h3{
    text-align: center;
    font-size: 24px;
    margin-bottom: 30px;
  }

  & p {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 8px;
    font-size: 18px;
    line-height: 30px;
  }
`;

const FlexArea = styled.div`
  display: flex;
  gap: 20px;
`;

const FlexItem = styled.div`
  flex: 4;
`;

const FlexWideItem = styled.div`
  flex: 5;
`;

const LinkButton = styled.button`
  border: none;
  height: 36px;
  width: 180px;
  background: #14457A;
  color: #fff;
  float: right;
  transform: translate(-20px, 25px);
  box-shadow: 1px 1px 4px rgba(50,50,50,0.3);
  border-radius: 3px;
`;

const Title = styled.div`
  width: 100%;
  border-radius: 3px;
  box-shadow: 1px 1px 4px rgba(50,50,50,0.3);
  padding: 8px;
  font-weight: 600;
`;