import { fileAPI } from '@/api/file';
import { qnaAPI } from '@/api/qna';
import { action, makeObservable, observable } from 'mobx';
import { noticeAPI } from '../../api/notice';
import { QnaListModel } from '../models/QnaListModel';

export interface QnaStoreInfo {
  qnaList: QnaListModel;
}

export class QnaStore implements QnaStoreInfo {
  qnaList: QnaListModel | any;

  constructor() {
    this.qnaList = [];

    makeObservable(this, {
      qnaList: observable,
      getQnaList: action,
      fileUpload: action,
      addQuestion: action,
      deleteQuestion: action,
    })
  }

  getQnaList = async () => {
    const [ret, err]: any = await qnaAPI.getQuestionList<QnaListModel>();
    if (err) {
      return 'err';
    } else {
      if (ret) {
        this.qnaList = ret.resultList;
        return ret;
      }
    }
  }
  fileUpload = async (file: any) => {
    const [ret, err] = await fileAPI.uploadFile(file);
    if (err) {
      return 'err';
    } else if (ret) {
      return ret;
    }
  }
  addQuestion = async (query?: any) => {
    const [ret, err] = await qnaAPI.addPost(query);
    if (err) {
      return 'err';
    } else if (ret) {
      return ret;
    }
    this.getQnaList();
  }


  deleteQuestion = async (params?: any) => {
    const [ret, err] = await noticeAPI.deletePost(params);
    if (err) {
      return 'err';
    } else if (ret) {
      return 'success';
    }
    this.getQnaList();
  }

}

export const qnaStore = new QnaStore();

