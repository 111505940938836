import React, { lazy, Suspense } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import PortalPage from '../components/views/pages/PortalPage'
import CompanyPage from '../components/views/pages/CompanyPage'
import SolutionsPage from '../components/views/pages/SolutionsPage'
import InfraPage from '../components/views/pages/InfraPage'
// import IndustriesPage from '../components/views/pages/IndustriesPage'
// import MediaPage from '../components/views/pages/MediaPage'
import RouteBoard from './RouteBoard'

const MediaPage = lazy(() => import('../components/views/pages/MediaPage'))
const IndustriesPage = lazy(() => import('../components/views/pages/IndustriesPage'))

export default function Router() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/SOXHome" element={<PortalPage />}></Route>
        <Route path="/SOXHome/company" element={<CompanyPage />}></Route>
        <Route path="/SOXHome/solutions" element={<SolutionsPage />}></Route>
        <Route path="/SOXHome/infra" element={<InfraPage />}></Route>
        <Route path="/SOXHome/industries" element={<IndustriesPage />}></Route>
        <Route path="/SOXHome/media" element={<MediaPage />}></Route>
        <Route path="/SOXHome/board/*" element={<RouteBoard />}></Route>
      </Routes>
    </Suspense>
  )
}


