import * as React from 'react';
import Dialog, { DialogProps as _DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from './Button';
import { useEffect, useState } from 'react';

interface DialogProps extends _DialogProps {
  button: string;
  buttonColor?: string;
  text?: string;
  subText?: string;
  content?: React.ReactNode;
  ms?: number;
  handleEvent?: () => void;
}

export function ConfirmMsg({ ...props }: DialogProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const eventHandler = () => {
    props.handleEvent !== undefined && props.handleEvent();
    handleClose();
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen} background={props.buttonColor} text={props.button} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.text}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.subText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={eventHandler} text={'확인'} />
          <Button onClick={handleClose} autoFocus text={'취소'} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function DialogMsg({ ...props }: DialogProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen} background={props.buttonColor} text={props.button} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.text}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.subText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus text={'확인'} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function CustomDialog({ ...props }: DialogProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen} background={props.buttonColor} text={props.button} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          {props.content}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus text={'확인'} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function TimeoutDialog({ ...props }: DialogProps) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    open && setTimeout(() => setOpen(false), props.ms)
  }, [open])



  return (
    <div>
      <Button onClick={handleClickOpen} background={props.buttonColor} text={props.button} />
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.text}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.subText}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}